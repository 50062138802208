.signin-loader-container,
.outpage-message {
  display: flex;
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  .messageSpan {
    font-family: monospace;
    letter-spacing: -0.04em;
    font-weight: 500;
    font-size: 14px;
  }
  .hide {
    display: none !important;
  }

  .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
}

.signin-container {
  display: flex;
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  .signin-form-section {
    width: 50%;
    margin-top: 48px;
    .welcome-text {
      font-family: monospace;
      letter-spacing: -0.04em;
      font-weight: 700;
      font-size: 40px;
      margin-top: 24px;
      color: #1e3079;
    }
    .welcome-sub-text {
      font-family: monospace;
      letter-spacing: -0.01em;
      font-weight: 450;
      font-size: 20px;
      margin-top: 16px;
      color: #5264b4;
    }
    .username-password-container {
      margin-top: 20px;
      .username-field,
      .password-field {
        margin-top: 20px;
        .MuiFormControl-root {
          .MuiFormLabel-root {
            color: #1e3079 !important;
          }
          .Mui-error {
            color: rgb(211, 47, 47) !important;
          }
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #4b599433 !important;
          }
          .MuiOutlinedInput-input {
            color: #191a1c;
          }
          .MuiInputAdornment-root {
            .MuiSvgIcon-root {
              fill: #7583c3;
            }
          }
        }
        .MuiInputBase-root {
          color: #c2c9e5 !important;
        }
      }
    }

    .login-button {
      margin-top: 20px;
      .login-button-text {
        text-align: center;
        border-radius: 100px;
        padding: 16px;
        background: #fad559;
        cursor: pointer;
        .MuiButtonBase-root {
          text-transform: none !important;
          color: #191a1c !important;
          font-size: 18px !important;
          font-weight: 500 !important;
          line-height: unset !important;
          padding: unset !important;
        }
        .arrow {
          padding-left: 6px;
          font-size: 18px;
        }
      }
    }
    .login-support-text {
      margin-top: 40px;
      span {
        &:nth-child(1) {
          font-family: monospace;
          font-size: 20px;
          letter-spacing: -0.02em;
          color: #7583c3;
          font-weight: 450;
        }
        &:nth-child(2) {
          padding-left: 10px;
          font-family: monospace;
          font-size: 20px;
          letter-spacing: -0.04em;
          color: #1e3079;
          font-weight: 700;
        }
      }
      .sign-up-text {
        cursor: pointer;
      }
    }

    .enable-ssi-qr-section {
      .separator {
        text-align: center;
        font-size: 22px;
        margin-top: 4%;
        margin-bottom: 2%;
        font-family: monospace;
        letter-spacing: -0.04em;
        color: #1e3079;
      }
      .login-vc-container {
        text-align: center;

        h2 {
          font-size: 26px;
          font-weight: 700;
          padding-bottom: 10px;
          font-family: monospace;
          letter-spacing: -0.04em;
          color: #1e3079;
        }

        .vc-invitation {
          b {
            font-family: monospace;
            letter-spacing: -0.04em;
            font-weight: 500 !important;
            font-size: 16px;
            color: #1e3079;
          }
        }

        .MuiButton-root {
          display: flex;
          border: 2px solid #1e3079;
          background-color: #5264b4;
          margin-bottom: 2%;
          margin-left: 20%;
          .MuiAvatar-root {
            .MuiAvatar-img {
              width: 24px;
            }
          }
        }
        .deep-link-help-text {
          color: #5264b4;
          font-family: monospace;
          letter-spacing: -0.04em;
          font-size: 14px;
        }
      }
    }
  }
  .signin-image-section {
    display: flex;
    justify-content: end;
    width: 50%;
    .image-cell {
      img {
        height: 550px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .signin-image-section {
    .image-cell {
      img {
        height: 500px !important;
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .signin-form-section {
    width: 45% !important;
  }
  .signin-image-section {
    width: 55% !important;
    .image-cell {
      img {
        height: 450px !important;
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .signin-form-section {
    width: 40% !important;
    margin-top: 24px !important;
    .welcome-text {
      font-size: 32px !important;
      margin-top: 16px !important;
    }
    .welcome-sub-text {
      font-size: 14px !important;
      margin-top: 10px !important;
    }
    .username-password-container {
      margin-top: 10px !important;
      .username-field,
      .password-field {
        margin-top: 10px !important;
      }
    }

    .login-button {
      margin-top: 10px !important;
      .login-button-text {
        padding: 14px !important;
        .MuiButtonBase-root {
          font-size: 14px !important;
        }
      }
    }
    .login-support-text {
      margin-top: 20px !important;
      span {
        &:nth-child(1) {
          font-size: 14px !important;
        }
        &:nth-child(2) {
          padding-left: 8px;
          font-size: 14px !important;
        }
      }
    }
  }
  .signin-image-section {
    width: 60% !important;
    .image-cell {
      img {
        height: 400px !important;
      }
    }
  }
}

@media only screen and (max-width: 778px) {
  .signin-container {
    flex-flow: column !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    height: calc(100vh - 200px) !important;
  }

  .signin-form-section {
    width: 95% !important;
  }

  .signin-image-section {
    width: 95% !important;
    justify-content: center !important;
    margin-top: 24px !important;
  }
}

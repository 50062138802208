.step-wrapper {
  padding: 16px;
  .step-header-section {
    margin-top: 10px;
    .step-header {
      font-family: monospace;
      font-weight: 700;
      font-size: 32px;
      letter-spacing: -0.04em;
      color: #1e3079;
      padding-bottom: 8px;
    }

    .step-paragraph {
      font-family: monospace;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.02em;
      color: #5264b4;
    }
  }
  .step-form {
    width: 90%;
    min-height: 210px;
    min-width: 300px;
    margin: 20px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    .MuiFormControl-root {
      margin-bottom: 10px;
      .MuiFormLabel-root {
        color: #1e3079 !important;
      }
      .Mui-error {
        color: rgb(211, 47, 47) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #4b599433 !important;
      }
      .MuiOutlinedInput-input {
        color: #191a1c;
      }
    }
    .MuiInputBase-root {
      color: #c2c9e5 !important;
    }
  }

  .error {
    position: absolute;
    color: red;
    font-style: italic;
    padding-left: 10px;
    font-size: smaller;
  }

  .MuiSelect-select {
    text-align: left;
    padding-left: 15px;
  }

  .filled label {
    transform: translate(14px, -6px) scale(0.75);
  }

  .filled legend {
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  }

  .submit-wrapper {
    font-family: monospace;
    text-align: center;
    color: white;
  }

  .submit-info h2 {
    color: #353535d8;
    margin: 15px 0;
  }
}

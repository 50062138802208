.feature-wrapper-review {
  display: flex;
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  .content {
    padding: 32px;
    border-radius: 28px;
    color: #ffffff;
    border: 1px solid #eef0f2;
    box-shadow: 0px 12px 24px 0px #b8c3f12e;
    text-align: center;
    max-width: 40%;
    .search-image {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        height: 100px;
        width: 100px;
        fill: #f2dede;
      }
    }
    .text {
      font-family: monospace;
      font-weight: 700;
      font-size: 22px;
      letter-spacing: -0.04em;
      color: #1e3079;
      padding-bottom: 20px;
    }
    .sub-text {
      font-family: monospace;
      font-weight: 450;
      font-size: 14px;
      letter-spacing: -0.04em;
      color: #1e3079;
      padding-bottom: 20px;
    }
  }
}

.feature-wrapper-reject {
  display: flex;
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  .content {
    padding: 32px;
    border-radius: 28px;
    color: #ffffff;
    border: 1px solid #eef0f2;
    box-shadow: 0px 12px 24px 0px #b8c3f12e;
    text-align: center;
    max-width: 40%;
    .error-image {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .text-danger {
      font-family: monospace;
      font-weight: 700;
      font-size: 22px;
      letter-spacing: -0.04em;
      color: #1e3079;
      padding-bottom: 20px;
    }
    .sub-text-danger {
      font-family: monospace;
      font-weight: 450;
      font-size: 14px;
      letter-spacing: -0.04em;
      color: #5264b4;
      padding-bottom: 20px;
    }
    .contact-button {
      .btn-contact {
        width: 100%;
        border-radius: 100px;
        color: #191a1c;
        background: #fad559;
        border-color: #fad559;
        font-family: monospace;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.02em;
      }
    }
  }
}

@media only screen and (max-width: 778px) {
  .feature-wrapper-reject {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: calc(100vh - 200px) !important;
    .content {
      max-width: 90% !important;
      padding: 16px !important;
    }
  }
}

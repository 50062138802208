@import '../../common/baseStyle/base.scss';

.home-screen {
  height: calc(100vh - 120px);
  padding-left: 96px;
  padding-right: 96px;
  overflow: auto;
  .body-screen {
    display: flex;
    justify-content: center;
    .body-content-text {
      margin-top: 1%;
      .top-text {
        font-family: monospace;
        letter-spacing: -0.06em;
        font-weight: 700;
        font-size: 116px;
        color: #1e3079;
        &:nth-child(1) {
          text-align: center;
        }
      }
      .bottom-text {
        color: #5264b4;
        font-weight: 400;
        font-family: monospace;
        font-size: 20px;
        padding-top: 2%;
        padding-bottom: 2%;
        letter-spacing: -0.03em;
        margin-left: 15%;
        margin-right: 15%;
        p {
          text-align: center;
        }
      }
    }
    .body-menu-nav .body-menu {
      display: flex;
      justify-content: space-between;
    }
    .nav-item {
      padding-top: 4%;
      text-align: center;
      .menu-item {
        a {
          color: black;
        }

        .getStarted {
          font-family: monospace;
          font-weight: 600;
          letter-spacing: -0.02em;
          border: 1px solid white;
          padding: 12px 30px;
          border-radius: 10px;
          background: #fad559;
          color: #1e3079;
          .arrow {
            padding-left: 4px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .body-screen-menu-item {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    #icons-grid {
      display: flex;
      .hero-cell {
        border-radius: 8px;
        background: #ffffff1a;
        border: 1px solid #ffffff1a;
        box-shadow: 0px 12px 24px 0px #b8c3f14d;
        margin-right: 8px;
        /**__*/
        padding: 16px;
        border: 1px solid white;
        display: flex;
        min-width: 260px;
        /**__*/
        .hero-cell-text {
          padding-left: 16px;
          p {
            &:nth-child(1) {
              font-size: 18px;
              font-family: monospace;
              letter-spacing: -0.01em;
              font-weight: 500;
              color: #1e3079;
              padding-bottom: 6px;
            }
            &:nth-child(2) {
              font-family: monospace;
              letter-spacing: -0.04em;
              font-size: 12px;
              font-weight: 500;
              color: #a1a1aa;
            }
          }
        }
      }
      img {
        height: 32px !important;
        width: 32px !important;
      }
    }
  }
  .body-screen-image {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 1150px) {
  .body-content-text {
    .top-text {
      font-size: 90px !important;
    }
    .bottom-text {
      font-size: 16px !important;
      margin-left: 12% !important;
      margin-right: 12% !important;
    }
  }

  .body-screen-image {
    width: 80% !important;
  }

  #icons-grid {
    display: unset !important;
    position: relative !important;
    background: unset !important;
    border: unset !important;
    border-radius: unset !important;
    width: 80% !important;
    .hero-cell {
      margin-bottom: 4% !important;
    }
  }
}

@media (max-width: 767px) {
  .home-screen {
    padding-left: 24px !important;
    padding-right: 24px !important;
    height: calc(100vh - 200px) !important;
  }
  .body-content-text {
    .top-text {
      font-size: 60px !important;
    }
    .bottom-text {
      font-size: 12px !important;
      margin-left: 10% !important;
      margin-right: 10% !important;
    }
  }
}

@media only screen and (max-width: 650px) {
  .body-content-text {
    .top-text {
      font-size: 40px !important;
    }
    .bottom-text {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 400px) {
  .body-content-text {
    top: 12% !important;
  }
}

@media (max-width: 499px) {
  .body-content-text {
    top: 14% !important;
  }
}
